

































import { Component, Vue } from 'vue-property-decorator';

import { publicPageGuard } from '@/app/shared/config/auth-guards';
import { isAdminUserAction } from '@/app/shared/actions/UserRolesActions';

import rules from '@/app/shared/validation-rules';
import { sendPasswordResetEmailAction } from '@publicApp/shared/actions';
import routesNames, { AdminRoutes } from '@/app/shared/utilities/routes-names';

import FormComponent from '@/app/shared/components/FormComponent.vue';
import AppPublicLayout from '@publicApp/shared/components/AppPublicLayout.vue';
import { UserCredential } from '@firebase/auth-types';
import namespace from '@/app/shared/store/namespace';

@Component({
  beforeRouteEnter: publicPageGuard,
  components: {
    FormComponent,
    AppPublicLayout,
  },
  data: (vm: any) => ({
    emailRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.emailFormat(vm.$i18n.t('invalid_email')),
    ],
    passwordRules: [rules.required(vm.$i18n.t('field_required'))],
  }),
})
export default class ForgetPassword extends Vue {
  public email = '';

  public async forgetPassword() {
    try {
      await sendPasswordResetEmailAction(this.email);
    } catch (error) {
      throw new Error(`Error while sending forget password email => ${error.message}`);
    }
  }
}
